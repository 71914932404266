import Table from '@/mixins/standard/tableSelect'
import { originData, statusOptions, originMapping, checkOptions } from '@/utils/constants'

export default {
  mixins: [Table],
  data() {
    return {
      originData: originData,
      statusOptions: statusOptions,
      originMapping,
      checkOptions: checkOptions
    }
  }
}
